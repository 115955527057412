import $ from 'jquery'

export default function bindUI(setPointsByCategory, clearPoints) {
    Array.from(document.getElementsByClassName('year')).map((yearElement) => {
        yearElement.addEventListener("click", (event) => {
            let cause = window.localStorage.getItem('cause')
            window.localStorage.setItem('year', event.target.innerText)
            let sex = window.localStorage.getItem('sex')

            clearPoints()
            setPointsByCategory(cause, event.target.innerText, sex)
            selectOption('year', event.target.innerText)
        });
    })

    Array.from(document.getElementsByClassName('category')).map((categoryElement) => {
        categoryElement.addEventListener("click", (event) => {
            window.localStorage.setItem('cause', event.target.innerText)
            let year = window.localStorage.getItem('year')
            let sex = window.localStorage.getItem('sex')

            clearPoints()
            setPointsByCategory(event.target.innerText, year, sex)
            selectOption('category', event.target.innerText)
        });
    })
}

export function selectOption(type, optionName) {
    Array.from(document.getElementsByClassName(type)).map((element) => element.style.color = '')
    document.getElementById(optionName).style.color = '#dd390a'
    if (type == 'category') {
        showhide(document.getElementById(optionName).nextElementSibling)
    }
}

export function fadeIntroduction() {
    let el = document.getElementById('loading');
    let newEl = document.createElement('h3');
    newEl.innerHTML = 'Click here to continue';
    newEl.onclick = () => {
        fade(document.getElementById('description'))
        unfade(document.getElementById('infographic'))
    }
    el.parentNode.replaceChild(newEl, el);
}

export function fade(element) {
    var op = 1; // initial opacity
    var timer = setInterval(function () {
        if (op <= 0.1) {
            clearInterval(timer);
            element.style.display = 'none';
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 50);
}

export function unfade(element) {
    var op = 0.1; // initial opacity
    element.style.display = 'block';
    var timer = setInterval(function () {
        if (op >= 1) {
            clearInterval(timer);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.1;
    }, 10);
}

export function showhide(elem) {
    if (document.getElementById) {
        var divid = elem;
        var divs = document.getElementsByClassName("hideable");
        for (var i = 0; i < divs.length; i = i + 1) {
            $(divs[i]).fadeOut("slow");
        }
        if(divid) $(divid).fadeIn("slow");
    }
    return false;
}