const TWEEN = require('@tweenjs/tween.js')
import * as THREE from 'three'


export default class DataPoint {
    constructor(location, scene) {
        this.location = location
        this.scene = scene
        this.target = {
            position: new THREE.Vector3(
                randomIntFromInterval(-400, 400),
                randomIntFromInterval(-400, 400),
                randomIntFromInterval(-400, 400)),
            rotation: new THREE.Vector3()
        }

        this.createPoint()
    }

    createPoint() {
        let geometry = new THREE.BoxGeometry(.2, (this.location.deaths / 1000000), .2)
        let material = new THREE.MeshBasicMaterial({
            color: 'red',
        })
        let cube = new THREE.Mesh(geometry, material)
        cube.name = 'point'
        cube.position.set(
            this.target.position.x,
            this.target.position.y,
            this.target.position.z
        )
        cube.userData = this.location

        this.cube = cube;
        this.scene.add(cube)

        placeObjectOnSphere(this.target, this.location.lat, this.location.lon, 50)
        this.animateToPosition()
    }

    animateToPosition() {
        animateVector3(this.cube.position, this.target.position, {
            duration: 2000,
            easing: TWEEN.Easing.Quintic.InOut
        })
        animateVector3(this.cube.rotation, this.target.rotation, {
            duration: 2000,
            easing: TWEEN.Easing.Quintic.InOut
        })
    }

    remove() {
        this.target.position = new THREE.Vector3(
            randomIntFromInterval(-700, 700),
            randomIntFromInterval(-700, 700),
            randomIntFromInterval(-700, 700)
        )
        animateVector3(this.cube.position, this.target.position, {
            duration: 2000,
            easing: TWEEN.Easing.Quintic.InOut,
            callback: () => {
                this.scene.remove(this.cube);
            }
        })
    }
}

function animateVector3(vectorToAnimate, target, options) {
    options = options || {};
    // get targets from options or set to defaults
    var to = target || THREE.Vector3(),
        easing = options.easing || TWEEN.Easing.Quadratic.In,
        duration = options.duration || 2000;
    // create the tween
    var tweenVector3 = new TWEEN.Tween(vectorToAnimate)
        .to({
            x: to.x,
            y: to.y,
            z: to.z,
        }, duration)
        .easing(easing)
        .onUpdate(function (d) {
            if (options.update) {
                options.update(d);
            }
        })
        .onComplete(function () {
            if (options.callback) options.callback();
        });
    // start the tween
    tweenVector3.start();
    // return the tween in case we want to manipulate it later on
    return tweenVector3;
}

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function fetchLocationsByParameters(data, cause, year, sex) {
    let locations = []
    Object.keys(data[cause][year][sex]).map(function (key, index) {
        locations.push(...data[cause][year][sex][key]['locations'])
    })
    return locations
}

function placeObjectOnSphere(object, lat, lon, radius) {
    var latRad = lat * (Math.PI / 180);
    var lonRad = -lon * (Math.PI / 180);
    // object.position.set(

    object.position.set(
        Math.cos(latRad) * Math.cos(lonRad) * radius,
        Math.sin(latRad) * radius,
        Math.cos(latRad) * Math.sin(lonRad) * radius
    );
    object.rotation.set(0.0, -lonRad, latRad - Math.PI * 0.5);
}