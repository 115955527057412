import * as THREE from 'three'
import * as d3 from 'd3'

export default async function Globe(style, maxAnisotropy, callback) {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic2xva2l0cyIsImEiOiJjamttdXFmYzUwYXozM3BxbDJlc3lmNDdwIn0.nQUSkkbAMw_HbEh8GVAisg'

    var zoom = 1;
    var mapWidth = 2048 * zoom;
    var mapHeight = 2048 * zoom;

    var $map = document.getElementById('map');
    var $eMap = document.getElementById('equirectangular-map');

    $map.style.width = mapWidth + 'px';
    $map.style.height = mapHeight + 'px';

    var dpr = window.devicePixelRatio;
    var width = mapWidth * dpr;
    var height = mapHeight * dpr;
    var halfHeight = height / 2;

    $eMap.width = width;
    $eMap.height = halfHeight;

    // Earth
    var texture = new THREE.CanvasTexture($eMap);
    texture.anisotropy = maxAnisotropy
    texture.minFilter = THREE.LinearFilter; // Fix "Textures should be of a power of two" warning
    var geometry = new THREE.SphereGeometry(50, 50, 50);
    var material = new THREE.MeshBasicMaterial({
        // color: 0x123,
        // transparent: true,
        overdraw: 30, // fill in the gaps between triangles
    });
    var earth = new THREE.Mesh(geometry, material);
    earth.visible = false
    earth.name = 'globe'


    // Projections stuff
    var equirectangular = d3.geoEquirectangular()
        .scale(width / 2 / Math.PI)
        .translate([width / 2, height * .75]);
    var mercator = d3.geoMercator()
        .scale(width / 2 / Math.PI)
        .translate([width / 2, height / 2]);
    var invert = equirectangular.invert;

    var context = $eMap.getContext('2d', {
        alpha: false
    });

    console.log('Generate map: ' + style);

    var map = new mapboxgl.Map({
        container: 'map',
        // optimize=true -> https://blog.mapbox.com/style-optimized-vector-tiles-39868da81275
        style: 'mapbox://styles/' + style + '?optimize=true',
        center: [0, 0],
        zoom: zoom,
        gridLayer: false,
        interactive: false,
        renderWorldCopies: false,
        attributionControl: false,
        trackResize: false,
        preserveDrawingBuffer: true,
    });

    map.on('load', function () {
        var canvas = map.getCanvas();
        var gl = canvas.getContext('webgl', {
            alpha: false,
            antialias: false
        });


        console.log('Render map start');
        var source = new Uint8Array(gl.drawingBufferWidth * gl.drawingBufferHeight * 4);
        gl.readPixels(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight, gl.RGBA, gl.UNSIGNED_BYTE, source);
        var sourceData = new Uint8ClampedArray(source.buffer);
        var targetData = new Uint8ClampedArray(sourceData.length / 2).fill(255);

        // This part is really slow >:(
        console.time('Reprojection');
        var w = width;
        var h = height;
        var x = 0;
        var y;
        for (; x < w; x++) {
            for (y = 0; y < h; y++) {
                var pixels = mercator(invert([x, y]));
                if (!isNaN(pixels[1])) {
                    var sourceIndex = 4 * (~~pixels[0] + w * ~~pixels[1]);
                    // Would have been x + w + h if it's not WebGL context
                    // (h-y-1) is to flip the Y (vertical) because WebGL texture starts at the bottom
                    var targetIndex = 4 * (x + w * (h - y - 1));
                    targetData[targetIndex] = sourceData[sourceIndex];
                    targetData[targetIndex + 1] = sourceData[sourceIndex + 1];
                    targetData[targetIndex + 2] = sourceData[sourceIndex + 2];
                    // targetData[targetIndex + 3] = 255; // Already filled
                }
            }
        }
        console.timeEnd('Reprojection');

        // $eMap.width = $eMap.width;
        var target = context.createImageData(width, halfHeight);
        target.data.set(targetData);

        // Draw the equirectangular projection canvas
        context.clearRect(0, 0, $eMap.width, $eMap.height);
        context.putImageData(target, 0, 0);

        // Cover the North pole
        for (var y = 0; y < halfHeight; y++) {
            var index = y * w * 4;
            var firstColor = targetData[index];
            var color = 'rgb(' + targetData[index] + ',' + targetData[index + 1] + ',' + targetData[index + 2] + ')';
            context.fillStyle = color;
            context.fillRect(0, 0, w, y);
            break;
        }

        // Cover the South pole
        for (var y = halfHeight - 1; y >= 0; --y) {
            var index = y * w * 4;
            var firstColor = targetData[index];
            var color = 'rgb(' + targetData[index] + ',' + targetData[index + 1] + ',' + targetData[index + 2] + ')';
            context.fillStyle = color;
            context.fillRect(0, y + 1, w, halfHeight - y);
            break;
        }

        console.log('Render map end');


        // Set texture on first load
        if (!material.map) {
            material.map = texture;
            material.needsUpdate = true;
            callback(earth)
        }
        // Update texture
        texture.needsUpdate = true;

        map.remove();
    }); // Delay for the tiles to render properly

    return earth
}