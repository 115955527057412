//
// ─── CONSTANTS AND IMPORTS ──────────────────────────────────────────────────────
//

import deaths from './data/global_deaths_small.data'
import * as THREE from 'three'
import setGlobe from './js/mapboxUtilities.js'
import bindUI, {
  selectOption,
  fadeIntroduction,
  showhide
} from './js/interfaceUtilities'
const TWEEN = require('@tweenjs/tween.js')
import DataPoint, {
  fetchLocationsByParameters
} from './js/dataUtilities'
import {
  bindTooltip,
  renderHighlight
} from './js/tooltipUtilities'
const OrbitControls = require('three-orbit-controls')(THREE)

const DEATHS_FILE_SIZE = 57033626
let deathDB = [],
  pointDB = [],
  globe
let loader, scene, camera, controls, renderer, clock, counter, cssRenderer
const manager = new THREE.LoadingManager()

//
// ─── UTILITY METHODS ────────────────────────────────────────────────────────────
//

function clearPoints() {
  while (pointDB.length) {
    let point = pointDB.pop()
    point.remove()
  }
}

function setPointsByCategory(cause, year, sex) {
  let locations = fetchLocationsByParameters(deathDB, cause, year, sex)

  let points = locations.map((location) => {
    return new DataPoint(location, scene)
  })
  pointDB.push(...points)
}

//
// ─── LIFECYCLE ──────────────────────────────────────────────────────────────────
//

function init() {
  window.localStorage.setItem('year', '2000')
  window.localStorage.setItem('cause', 'Diseases')
  window.localStorage.setItem('sex', 'BTSX')

  clock = new THREE.Clock()
  loader = new THREE.FileLoader(manager)
  scene = new THREE.Scene()
  camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  controls = new OrbitControls(camera)
  scene.add(new THREE.AmbientLight(0xffffff))
  renderer = new THREE.WebGLRenderer({
    alpha: true,
    stencil: false,
    antialias: false
  })

  controls.enableDamping = true
  controls.dampingFactor = 0.1
  controls.rotateSpeed = 0.08
  controls.enablePan = false
  controls.autoRotateSpeed = 0.2
  // controls.maxDistance = 50
  // controls.minDistance = -50

  // on globe drag, pause auto rotation
  controls.addEventListener('start', () => {
    counter = new Date()
  })
  controls.addEventListener('end', () => {
    if ((new Date()) - counter > 150) {
      controls.autoRotate = false
      setTimeout(() => {
        if ((new Date()) - counter > 5000) controls.autoRotate = true
      }, 5000)
    }
  })

  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.domElement.id = 'deathCanvas';
  document.getElementById('infographic').appendChild(renderer.domElement);
}

function update() {
  requestAnimationFrame(update)
  var delta = clock.getDelta()
  controls.update(delta)
  // console.log(controls);

  TWEEN.update()
  renderHighlight(renderer, scene, camera)
  renderer.render(scene, camera)
}

window.onresize = function () {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
  cssRenderer.setSize(window.innerWidth, window.innerHeight)
}

//
// ─── RUNTIME FUNTIONALITY ───────────────────────────────────────────────────────
//

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  document.getElementById('loading').style.display = 'none'
  document.getElementById('description-text').style.display = 'none'
  document.getElementById('description-title').innerHTML = "Oh noes! we are not supporting mobile devices yet.. don't die"
 } else {
  init()
  bindUI(setPointsByCategory, clearPoints)
  // showhide()
  
  loader.setResponseType('json').load(deaths, (data) => {
    deathDB = data
  }, (xhr) => {
    let precentage = (xhr.loaded / DEATHS_FILE_SIZE * 100)
    document.getElementById('bar').style.width = `${precentage}%`
    document.getElementById('loading-counter').innerHTML = `${100 - Math.round(precentage)} people counted`
  })
  
  globe = setGlobe(
    'slokits/cjr43dkyp1a9h2slfd9ydgxpv',
    renderer.capabilities.getMaxAnisotropy(), (globe) => {
      update()
      scene.add(globe)
    })
  globe.then((globe) => {
    globe.visible = true
  })
  cssRenderer = bindTooltip(camera, scene, globe)
  
  manager.onLoad = function () {
    selectOption('category', 'Diseases')
    selectOption('year', '2000')
    setPointsByCategory('Diseases', '2000', 'BTSX')
    controls.autoRotate = true
    camera.position.z = 130;
    update();
    fadeIntroduction()
  }
 }

