import * as THREE from 'three'
import {
    isNull
} from 'util';
import $ from 'jquery'
import Chart from 'chart.js'
import popData from '../data/global_population.json'
require('./CSS3DRenderer')
Chart.defaults.global.defaultFontFamily = 'IBM Plex Mono';
Chart.defaults.global.defaultFontSize = 8;
Chart.defaults.global.defaultFontColor = 'red';
Chart.defaults.global.legend.display = false;
Chart.defaults.global.tooltips.enabled = false;

let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let INTERSECTED, TOOLTIP_ELEMENT, GLOBE_TOUCH, chart, tooltipData = {}
let tooltipRenderer, tooltipScene, sprite

String.prototype.trunc = function (n) {
    return this.substr(0, n - 1) + (this.length > n ? '...' : '');
};

function updateTooltipPosition(camera, scene) {
    if (TOOLTIP_ELEMENT) {
        sprite.position.copy( TOOLTIP_ELEMENT.position )
        let globe = scene.getObjectByName("globe")
        let globeDist = camera.position.distanceTo(globe.position)
        let tooltipDist = camera.position.distanceTo(sprite.position)
        let opacity = ((globeDist - tooltipDist) / 100) + 0.5
        document.getElementById('tooltip').style.opacity = opacity
    } else {
        document.getElementById('tooltip').style.opacity = 0
    }
}

function onMouseMove(event) {

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

}

function onMouseClick(event) {
    if (INTERSECTED) {
        TOOLTIP_ELEMENT = INTERSECTED
        let country = INTERSECTED.userData.country
        $('#tt-header').text(country)
        $('#tt-deaths').text("Deaths: " + new Intl.NumberFormat().format(Math.round(INTERSECTED.userData.deaths)))
        if (country in popData) {
            document.getElementById("tt-expectency").style.display = "block"
            $('#tt-expectency').text("Life Expectency: " + Math.round(popData[country].remaining_life_expectancy))

            document.getElementById("tt-distribution-title").style.display = "block"
            document.getElementById("tt-distribution").style.display = "block"
            let mortality = popData[country].mortality_distribution.slice(0, 20)
            chart.data.labels = mortality.map((stat) => stat.age)
            chart.data.datasets[0].data = mortality.map((stat) => stat.mortality_percent)
            chart.update()
        } else {
            document.getElementById("tt-expectency").style.display = "none"
            document.getElementById("tt-distribution-title").style.display = "none"
            document.getElementById("tt-distribution").style.display = "none"
        }
        $.getJSON("https://en.wikipedia.org/w/api.php?action=opensearch&search=" + country + "&format=json&callback=?",
            (data) => {
                $('#tt-snippet').text(data[2][0].trunc(120))
            })
 
    } else if(GLOBE_TOUCH == false) {
        TOOLTIP_ELEMENT = null
    }
}

export function renderHighlight(renderer, scene, camera) {
    updateTooltipPosition(camera, scene)
    tooltipRenderer.render(tooltipScene, camera)
    raycaster.setFromCamera(mouse, camera);
    let intersects = raycaster.intersectObjects(scene.children);

    if (intersects.length > 0) {
        if (intersects[0].object != INTERSECTED && intersects[0].object.name == 'point') {
            if (INTERSECTED) {
                INTERSECTED.material.color = new THREE.Color('red')
            }
            INTERSECTED = intersects[0].object;
            INTERSECTED.material.color = new THREE.Color(0xffffff)
            GLOBE_TOUCH = false;
        }
        if (intersects[0].object.name == 'globe') {
            if (INTERSECTED) INTERSECTED.material.color = new THREE.Color('red')
            INTERSECTED = null;
            GLOBE_TOUCH = true;
        }
    } else {
        if (INTERSECTED) INTERSECTED.material.color = new THREE.Color('red')
        INTERSECTED = null;
        GLOBE_TOUCH = false;
    }
}

export function bindTooltip(camera, scene, earth) {
    tooltipScene = new THREE.Scene()
    tooltipRenderer = new THREE.CSS3DRenderer({
        alpha: true,
        stencil: false,
        antialias: false
    })
    tooltipRenderer.domElement.style.position = 'absolute';
    tooltipRenderer.domElement.style.top = 0;

    tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
    tooltipRenderer.domElement.id = 'tooltipCanvas';
    document.getElementById('infographic').appendChild(tooltipRenderer.domElement);
    sprite = new THREE.CSS3DSprite(document.getElementById('tooltip'))
    sprite.scale.set(0.1, 0.1, 0.1)
    tooltipScene.add(sprite)

    chart = new Chart($('#tt-distribution'), {
        type: 'bar',
        data: {
            labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
            datasets: [{
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: 'red'
            }]
        },
        options: {
            scales: {
                yAxes: [{
                    gridLines: {
                        drawBorder: false,
                    },
                }]
            },
        }
    })
    window.addEventListener('mousemove', onMouseMove, false);
    window.addEventListener('click', onMouseClick, false);

    return tooltipRenderer
}